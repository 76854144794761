import React from 'react';
import { GetStaticProps } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { useLocation } from 'react-use';
import { NextSeo } from 'next-seo';
import Banner from '@/components/Home/Banner';
import Advantage from '@/components/Home/Advantage';
import Features from '@/components/Home/Features';
import Choose from '@/components/Home/Choose';
import Evaluation from '@/components/Home/Evaluation';
import Teachers from '@/components/Home/Teachers';
import InTheNews from '@/components/Home/InTheNews';
import Projects from '@/components/Home/Projects';
import Courses from '@/components/Home/Courses';
import Form from '@/components/Form';
import Blog from '@/components/Blog';
import { BlogPost } from '@/interface/home';
import { BLOG_URL } from '@/utils/constants';

export const getStaticProps: GetStaticProps = async ({ locale = '' }) => {
  let blogPosts: BlogPost[] = [];
  try {
    // 获取所有类别
    const posts = await fetch(`${BLOG_URL}/wp-json/wp/v2/wk_get_blog_posts?lang=${locale}&per_page=5`);
    const postsData: BlogPost[] = await posts.json();
    blogPosts = postsData;
  } catch (error) {
    console.log('blog err', error);
  }

  // 获取多语言翻译
  const translations = await serverSideTranslations(locale, ['common', 'home', 'path', 'form']);
  return {
    props: {
      ...translations,
      blogPosts: [...blogPosts],
    },
    // revalidate: 60 * 60 * 24 * 3, // 3天更新一次
  };
};
const Home = (props: { blogPosts: BlogPost[] }) => {
  const { blogPosts } = props;
  const { t } = useTranslation(['home', 'common']);
  const state = useLocation();
  return (
    <>
      <NextSeo
        openGraph={{
          url: state?.href,
          title: t('common:seo-首页-title'),
          description: t('common:seo-首页-description'),
          images: [
            {
              url: 'https://cdnwukong.com/images/public/og/offical-site-1.png',
              width: 300,
              height: 300,
              alt: t('common:seo-首页-title'),
            },
          ],
        }}
      />
      <Head>
        <title>{t('common:seo-首页-title')}</title>
        <meta name="description" content={t('common:seo-首页-description')} />
        <meta name="keywords" content={t('common:seo-首页-关键词')} />
        <meta
          data-n-head="ssr"
          data-hid="apple-mobile-web-app-title"
          name="apple-mobile-web-app-title"
          content={t('common:seo-首页-title')}
        />
        <link rel="canonical" href={state?.href} />
        <script type="text/javascript" src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js" async />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'Organization',
              title: t('common:seo-首页-title'),
              url: 'https://www.wukongsch.com',
              logo: 'https://cdnwukong.com/images/public/og/offical-site-1.png',
              brand: {
                '@type': 'Brand',
                name: 'WuKong Education',
              },
            }),
          }}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              '@context': 'https://schema.org/',
              '@type': 'Product',
              name: t('common:seo-首页-title'),
              description: t('common:seo-首页-description'),
              url: 'https://www.wukongsch.com',
              aggregateRating: {
                '@type': 'AggregateRating',
                ratingValue: '4.7',
                ratingCount: '36805',
                reviewCount: '1529',
              },
              brand: {
                '@type': 'Brand',
                name: 'WuKong Education',
              },
              operatingSystem: 'WINDOWS, MAC, iOS, ANDROID',
              offers: {
                '@type': 'AggregateOffer',
                offerCount: '999999999',
                lowPrice: '0.00',
                highPrice: '0.00',
                priceCurrency: 'USD',
              },
            }),
          }}
        />
      </Head>
      <style>{`
        #kaamelAlertBig,
        #kaamelAlertBig .panelFooter {
          bottom: 100px !important;
          @media (max-width: 720px) {
            bottom: 160px !important;
          }
        };
      `}</style>
      <style>{`
        #kaamelAlertBig{
          @media (max-width: 720px) {
            max-height:calc(min(87% - 130px, 760px)) !important;
          }

        }
      `}</style>
      <Banner />
      <Advantage />
      <Projects />
      <Courses />
      <Features />
      <Choose />
      <Evaluation />
      <Teachers />
      <InTheNews />
      <Form type="position" />
      <Blog blogPosts={blogPosts} />
    </>
  );
};

export default Home;
